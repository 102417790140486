import React, { useEffect, useState } from "react";
import classes from "./ViewProduct.module.css";
import { Avatar } from "@components";
import { Counter } from "./Counter/Counter";
import { NavLink, useParams } from "react-router-dom";
import { Slider } from "./Slider/Slider";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  SliderProducts,
  CircleSpinner,
  EmptyProductsMsg,
  Seo,
  Share,
} from "@components";
// import { Share } from "./Share/Share";
import { Helmet } from "react-helmet";
import { Config } from "@config/config";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Size } from "./Size/Size";
import { Color } from "./Color/Color";
import { useTranslation } from "react-i18next";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";

/** Redux **/
import { fetchProduct, fetchRelatedProducts } from "@store/product";
import { fetchCart, addCart, clearState } from "@store/cart";
import { useDispatch, useSelector } from "react-redux";
import { DataArray } from "@mui/icons-material";
import { slug } from "@services";

export const ViewProduct = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    data,
    loading,
    sizes: sizesData,
    sizeTypeId,
    colors: colorsData,
    colorTypeId,
  } = useSelector((state) => state.product.product);
  const relatedProducts = useSelector(
    (state) => state.product.relatedProducts.data
  );
  const relatedProductsLoading = useSelector(
    (state) => state.product.relatedProducts.loading
  );
  const { loading: cartLoading, success: successAddToCart } = useSelector(
    (state) => state.cart.addCart
  );

  const [counter, setCounter] = useState(1);
  const [size, setSize] = useState(null);
  const [color, setColor] = useState(null);
  const [availableQty, setAvailableQty] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [currentImgPreview, setCurrentImgPreview] = useState(null);
  const [previewSlider, setPreviewSlider] = useState(null);

  const [productPrice, setProductPrice] = useState({
    price: null,
    oldPrice: null,
  });

  // const onSelectSize = (e) => {
  //   const dataSelected = e.target.value;
  //   setSize(dataSelected);
  //   if (dataSelected?.price) {
  //     setProductPrice({
  //       price: dataSelected.price,
  //       oldPrice: dataSelected.oldPrice,
  //     });
  //   }
  // };

  const handleCounter = (data) => {
    setCounter(data);
  };

  useEffect(() => {
    dispatch(fetchProduct({ rowId: id }));
    dispatch(fetchRelatedProducts({ rowId: id }));
  }, [dispatch, id]);

  const onAddToCart = () => {
    dispatch(
      addCart({
        productId: data?.id,
        qty: counter,
        size,
        sizeTypeId,
        color,
        colorTypeId,
      })
    );
  };

  useEffect(() => {
    if (successAddToCart) {
      dispatch(clearState());
      dispatch(fetchCart());
    }
  }, [dispatch, successAddToCart]);

  useEffect(() => {
    if (data) {
      setProductPrice({ price: data?.price, oldPrice: data?.oldPrice });
      setCounter(1);
      setAvailableQty(data?.availableQty);
      setQuantity(data?.qty);
    }

    if (sizesData?.length > 0) {
      setSize(sizesData[0]?.id);

      // Available Qty
      if (sizesData[0]?.availableQty === null) {
        setAvailableQty(1);
      } else {
        setAvailableQty(sizesData[0]?.availableQty);
      }

      // Qty
      if (sizesData[0]?.qty === null) {
        setQuantity(null);
      } else {
        setQuantity(sizesData[0]?.qty);
      }

      // Price
      if (sizesData[0]?.price === null) {
        setProductPrice({ price: data?.price, oldPrice: data?.oldPrice });
      }
    }
  }, [data, sizesData]);

  const onSize = (row, id) => {
    setSize(id);
    // setColor(null);
    setCounter(1);

    // Price
    if (row?.price === null) {
      setProductPrice({ price: data?.price, oldPrice: data?.oldPrice });
    } else {
      setProductPrice({
        price: row?.price,
        oldPrice: row?.oldPrice,
      });
    }

    // Available Qty
    if (row?.availableQty === null) {
      setAvailableQty(1);
    } else {
      setAvailableQty(row?.availableQty);
    }

    // Quantity
    if (row?.qty === null) {
      setQuantity(null);
    } else {
      setQuantity(row?.qty);
    }
  };

  const onColor = (row, id) => {
    setColor(id);
    // setSize(null);
    setCounter(1);

    // Price
    if (row?.price === null) {
      setProductPrice({ price: data?.price, oldPrice: data?.oldPrice });
    } else {
      setProductPrice({
        price: row?.price,
        oldPrice: row?.oldPrice,
      });
    }

    // Available Qty
    if (row?.availableQty === null) {
      setAvailableQty(1);
    } else {
      setAvailableQty(row?.availableQty);
    }

    // Quantity
    if (row?.qty === null) {
      setQuantity(null);
    } else {
      setQuantity(row?.qty);
    }
  };

  useEffect(() => {
    if (data) {
      setCurrentImgPreview(data?.media?.url);
    }
  }, [data]);

  const onSetCurrentImg = (index, url) => {
    setCurrentImgPreview(url);
  };

  const onSetActiveToPreviewSlider = () => {
    setPreviewSlider(true);
  };

  const onClosePreviewSlider = (data) => {
    setPreviewSlider(false);
  };

  return (
    <>
      <Seo title={`${Config.name} | ${data?.title}`} />

      <div className={classes.container}>
        <div className={`container`}>
          <div className={`page-content page-content-product`}>
            <div className={classes.block}>
              {loading ? (
                <CircleSpinner size="lg" />
              ) : (
                <div className={classes.grid}>
                  <div className={`${classes.view}`}>
                    <div className={`${classes.preview}`}>
                      <InnerImageZoom
                        className={`${classes.previewImg}`}
                        src={currentImgPreview}
                        zoomScale={1.3}
                        hideCloseButton={true}
                      />
                      <Tooltip title={t("fullscreen")} arrow>
                        <button
                          onClick={onSetActiveToPreviewSlider}
                          className={`${classes.fullScreenBtn}`}>
                          <span
                            className={`icon-maximize ${classes.icon}`}></span>
                        </button>
                      </Tooltip>
                    </div>
                    <Slider
                      onCurrentImgFromChild={onSetCurrentImg}
                      onOpenPreviewSliderFromChild={onSetActiveToPreviewSlider}
                      mainImage={data?.media?.url}
                      gallery={data?.galleries}
                      previewSlider={previewSlider}
                      onClosePreviewSliderFromChild={onClosePreviewSlider}
                    />
                  </div>
                  <div className={classes.details}>
                    <div className={`mb-3`}>
                      <NavLink
                        className={`sub-title ${classes.categoryTitle}`}
                        to={`/c/${slug(data?.mainCategory?.name)}/${
                          data?.mainCategory?.encryptId ??
                          data?.mainCategory?.id
                        }?cid=${data?.mainCategory?.id}`}>
                        {data?.mainCategory?.name}
                      </NavLink>
                    </div>
                    <div className={`title ${classes.title} mb-3`}>
                      {data?.title}
                    </div>

                    {data?.brand ? (
                      <div className={``}>
                        <NavLink
                          className={`sub-title ${classes.categoryTitle}`}
                          to={`/b/${slug(data?.brand?.name)}/${
                            data?.brand?.encryptId ?? data?.brand?.id
                          }?cid=${data?.brand?.id}`}>
                          Brand: <b>{data?.brand?.name}</b>
                        </NavLink>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={`${classes.priceContainer} d-flex`}>
                      {productPrice?.oldPrice > productPrice?.price ? (
                        <div className={classes.price}>
                          {productPrice?.price} {t("currency")}
                          <span className={classes.oldPrice}>
                            {productPrice?.oldPrice} {t("currency")}
                          </span>
                        </div>
                      ) : (
                        <div
                          className={`${classes.withoutOldPrice} card-item-price card-item-price-gray`}>
                          {productPrice?.price} {t("currency")}
                        </div>
                      )}
                    </div>
                    <hr className={`divider`} />

                    {/* <div className={`${classes.code} mb-5`}>
                    <div className={`${classes.mainTitle}`}>
                      Item Code: 166107-24001-005
                    </div>
                  </div> */}

                    <div className={`${classes.description}`}>
                      <div className={`${classes.mainTitle}`}>
                        {t("productDescription")}
                      </div>

                      <div className={`text ${classes.text} mt-2 mb-5`}>
                        {data?.body ?? t("noDescription")}
                      </div>
                    </div>

                    <div className={`${classes.color} mb-4`}>
                      {loading ? (
                        t("loading")
                      ) : colorsData?.length > 0 ? (
                        <Color
                          onColorFromParent={onColor}
                          colors={colorsData}
                          color={color}
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {/* <div className={`${classes.guide}`}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.rivafashion.com/size-guide/tops.html"
                        className={`btn btn-normal`}>
                        Size Guide
                      </a>
                    </div> */}

                    <div
                      className={`${classes.share} d-flex align-items-center  mb-4  mt-4`}>
                      <span className={`${classes.titleGray}`}>
                        {t("share")}
                      </span>
                      <Share row={data} noTitle className={`pt-0`} />
                      {/* <Share /> */}
                    </div>

                    <div className={`${classes.size} mb-3`}>
                      {loading ? (
                        t("loading")
                      ) : sizesData?.length > 0 ? (
                        // <Box>
                        //   <TextField
                        //     displayEmpty
                        //     fullWidth
                        //     select
                        //     size="small"
                        //     label={"Choose Size"}
                        //     value={size}
                        //     onChange={onSelectSize}>
                        //     {sizesData?.map((row, index) => {
                        //       return (
                        //         <MenuItem key={row.id} value={row}>
                        //           {row.title}
                        //         </MenuItem>
                        //       );
                        //     })}
                        //   </TextField>
                        // </Box>
                        <Size
                          onSizeFromParent={onSize}
                          sizes={sizesData}
                          size={size}
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {quantity == 0 || quantity === null ? (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">
                          {t("sorry-this-item-doesnt-has-quantity")}
                        </Alert>
                      </Stack>
                    ) : (
                      <>
                        <div className={`${classes.counter}`}>
                          <Counter
                            onDataFromCounter={handleCounter}
                            counter={counter}
                            availableQty={availableQty}
                          />
                        </div>
                        <div className={[classes.action, "mt-3"].join(" ")}>
                          <Button
                            onClick={onAddToCart}
                            disabled={cartLoading}
                            className="mb-3"
                            size="large"
                            fullWidth
                            color="primary"
                            variant="outlined">
                            {cartLoading ? (
                              <>
                                <CircularProgress
                                  size={17}
                                  style={{ color: "#000" }}
                                />
                                {t("loading")}
                              </>
                            ) : (
                              t("add-to-cart")
                            )}
                          </Button>
                          {/* <Button
                            size="large"
                            fullWidth
                            color="primary"
                            variant="contained">
                            BUY IT NOW
                          </Button> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <SliderProducts
              noContainer
              padding
              items={relatedProducts}
              loading={relatedProductsLoading}
              index={1}
              viewAll={false}
              title={t("relatedProducts")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

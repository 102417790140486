import React, { useState } from "react";
import classes from "./Footer.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { LangSwitcher, Avatar } from "@components";
import { Subscribe } from "./subscribe/Subscribe";
import { Config } from "@config/config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Android from "@assets/img/android.png";
import Ios from "@assets/img/ios.png";

export const Footer = ({ socialMedia, whatsAppNumber }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const hideNavbarOnPages = Config.hideNavbarOnPages;
  const isHidden = hideNavbarOnPages.includes(location.pathname);
  const { data } = useSelector((state) => state.general.appSettings);

  const [items, setItems] = useState([
    {
      id: 1,
      title: t("customerCare"),
      children: [
        {
          id: 1,
          title: t("contact-us"),
          link: "/contact-us",
        },
        {
          id: 2,
          title: t("about-us"),
          link: "/about",
        },
        {
          id: 3,
          title: t("faqs"),
          link: "/faqs",
        },
        // {
        //   id: 3,
        //   title: t("return-policy"),
        //   link: "/refund-policy",
        // },
        // {
        //   id: 4,
        //   title: t("return-form"),
        //   link: "/return-form",
        // },
        // {
        //   id: 5,
        //   title: t("delivery"),
        //   link: "/delivery",
        // },
        // {
        //   id: 6,
        //   title: t("order-tracking"),
        //   link: "/order-tracking",
        // },
      ],
    },
    {
      id: 2,
      title: t("legal"),
      children: [
        {
          id: 1,
          title: t("terms-conditions"),
          link: "/terms-conditions",
        },
        {
          id: 2,
          title: t("privacy-policy"),
          link: "/privacy-policy",
        },
        // {
        //   id: 3,
        //   title: t("cookies-policy"),
        //   link: "/cookies-policy",
        // },
      ],
    },
  ]);

  if (isHidden) {
    return null;
  }
  return (
    <>
      <footer>
        <div className={classes.container}>
          <div className={`container`}>
            <div className={classes.block}>
              {/* Upper */}
              <div className={classes.upper}>
                <div className={classes.grid}>
                  {items?.map((row, index) => {
                    return (
                      <div key={row.id} className={classes.column}>
                        <div className={classes.title}>{row.title}</div>
                        <div className={classes.list}>
                          {row?.children?.map((child, idx) => {
                            return (
                              <div key={child.id} className={classes.listItem}>
                                <NavLink
                                  className={classes.listLink}
                                  to={child.link}>
                                  {child.title}
                                </NavLink>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <div className={classes.column}>
                    <div className={classes.title}>{t("subscribeTitle")}</div>
                    <div className={classes.description}>
                      {t("subscribeDescription")}
                    </div>
                    <Subscribe />
                  </div>
                  <div className={classes.column}>
                    <div className={classes.title}>{t("follow-us")}</div>
                    <div className={classes.socialMedia}>
                      {socialMedia &&
                        socialMedia?.map((row, index) => {
                          if (row.title !== "WhatsApp") {
                            return (
                              <a
                                target="_blank"
                                href={row.value}
                                rel="noreferrer">
                                <span
                                  className={`icon-${row.title.toLowerCase()} ${
                                    classes.icon
                                  }`}></span>
                              </a>
                            );
                          }
                        })}

                      {whatsAppNumber ? (
                        <a
                          target="_blank"
                          href={`https://wa.me/${whatsAppNumber}?&text=${t(
                            "hello-have-question-about"
                          )}`}
                          rel="noreferrer">
                          <span
                            className={`icon-whatsapp ${classes.icon}`}></span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className={`${classes.platformsContainer}`}>
                      <div className={`${classes.title} mb-2`}>
                        {t("download-now")}
                      </div>
                      <div className={`${classes.platforms}`}>
                        <a
                          className={`${classes.platformItem}`}
                          href={data?.androidAppUrl}
                          target="_blank"
                          rel="noreferrer">
                          <Avatar
                            className={`${classes.platformItemImg}`}
                            src={Android}
                            alt="Android"
                            width={1000}
                            height={1000}
                          />
                          {t("play-store")}
                        </a>
                        <a
                          className={`${classes.platformItem}`}
                          href={data?.iosAppUrl}
                          target="_blank"
                          rel="noreferrer">
                          <Avatar
                            className={`${classes.platformItemImg}`}
                            src={Ios}
                            alt="Ios"
                            width={1000}
                            height={1000}
                          />
                          {t("apple-store")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Bottom */}
              <div className={`${classes.bottom} white`}>
                {/* <LangSwitcher /> */}
                <div className={`${classes.bottom} white`}>
                  <LangSwitcher />
                  {/* <div>
                    <a
                      href="https://gatetechs.com/"
                      target="_blank"
                      rel="noreferrer">
                      <div className={classes.copyright}>Gate Techs</div>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className={`${classes.footerBottom}`}>
        <div className={`container`}>
          <div className={`${classes.footerText}`}>
            {t("footer-copyright", { year: new Date().getFullYear() })}
            <a
              className={`${classes.poweredBy}`}
              href="https://gatetechs.com/ar/"
              target="_blank">
              {t("gate-techs")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState } from "react";
import classes from "./CircleCollection.module.css";
import Button from "@mui/material/Button";
import { Link, NavLink } from "react-router-dom";
import { CircleCard, HeadingBar } from "@components";
import { useTranslation } from "react-i18next";

export const CircleCollection = ({
  more,
  gridCount,
  items,
  keyword,
  title,
  linkTitle,
  link,
  noPadding,
  noContainer,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className={`${classes.container} ${
          noPadding ? "" : classes.innerPadding
        } `}>
        <div className={`${noContainer ? "" : "container"}`}>
          {title ? (
            <HeadingBar title={title} linkTitle={linkTitle} link={link} />
          ) : (
            ""
          )}

          <div className={classes.block}>
            <div className={`${classes.grid}`}>
              {items?.map((row, index) => {
                return (
                  <CircleCard
                    className={classes.card}
                    row={row}
                    keyword={keyword}
                    keywordID={row?.id}
                    keywordEncryptID={row?.encryptId}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

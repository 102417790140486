import React, { useEffect, useState } from "react";
import classes from "./Home.module.css";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

/** Redux **/
import {
  fetchHome,
  fetchPopularProducts,
  fetchMostRecentProducts,
  fetchNewArrivalsProducts,
  fetchBrands,
  fetchOffers,
  fetchSale,
} from "@store/home";
import { fetchAppSettings } from "store/general";
import { useDispatch, useSelector } from "react-redux";

/** Third Party **/
import { Config } from "@config/config";
import { Header } from "./header/Header";
import { Grid } from "./grid/Grid";
import { Cover } from "./cover/Cover";
// import { SlideShow } from "./slideShow/SlideShow";
import { SliderProducts, SlideShow, Seo, CircleCollection } from "@components";
import { Collection } from "./collection/Collection";
import { Download } from "./download/Download";
import { MarqueeComponent } from "./marquee/Marquee";
import { SmallCollection } from "./smallCollection/SmallCollection";
import { GridDynamic } from "./gridDynamic/GridDynamic";

export const Home = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [superDeals, setSuperDeals] = useState(null);
  const { data, loading } = useSelector((state) => state.home.home);
  const homeStructureData = useSelector(
    (state) => state.general.appSettings.homeStructureData
  );
  // const popularProducts = useSelector(
  //   (state) => state.home.popularProducts.data
  // );
  // const mostRecentProducts = useSelector(
  //   (state) => state.home.mostRecentProducts.data
  // );
  // const newArrivalsProducts = useSelector(
  //   (state) => state.home.newArrivalsProducts.data
  // );
  // const brands = useSelector((state) => state.home.brands.data);
  // const offers = useSelector((state) => state.home.offers.data);
  // const sale = useSelector((state) => state.home.sale.data);

  const [videoUrls, setVideoUrls] = useState([]);
  useEffect(() => {
    dispatch(fetchHome());
    dispatch(fetchAppSettings());
    // dispatch(fetchPopularProducts());
    // dispatch(fetchMostRecentProducts());
    // dispatch(fetchNewArrivalsProducts());
    // dispatch(fetchBrands());
    // dispatch(fetchOffers());
    // dispatch(fetchSale());
  }, [dispatch]);

  // useEffect(() => {
  //   if (data?.homeVideos) {
  //     data.homeVideos &&
  //       data.homeVideos.map((row) => {
  //         if (row.media.type === "video") {
  //           setVideoUrls([
  //             ...videoUrls,
  //             { video: row?.media?.url, title: row?.title },
  //           ]);
  //         }
  //       });
  //   }
  // }, [data]);

  return (
    <>
      <Seo title={`${Config.name}`} />
      <div className={classes.homeContainer}>
        {/* {videoUrls ? <Header videoUrls={videoUrls} loading={loading} /> : ""}
        <MarqueeComponent
          title={t("featured-brands")}
          items={data?.popularBrands}
        />
        <Grid
          key={2}
          items={data?.bestSellingProducts}
          title={t("best-selling-products")}
        />
        <SlideShow items={data?.sliders} />
        <Grid
          key={1}
          items={data?.popularProducts}
          title={t("popular-products")}
          itemsCount={4}
        />
        {data?.bestSellingProducts ? (
          <SliderProducts
            items={data?.latestProducts}
            loading={loading}
            viewAll={true}
            title={t("latest-products")}
          />
        ) : (
          ""
        )}
        <Collection items={data?.superDeals} /> */}

        {/* <Download /> */}

        {homeStructureData?.map((row, index) => {
          if (row.toLowerCase() === "sliders") {
            return data?.sliders ? <SlideShow items={data?.sliders} /> : "";
          }

          if (row.toLowerCase() === "homevideo") {
            return videoUrls ? (
              <Header items={data?.homeVideos} loading={loading} />
            ) : (
              ""
            );
          }

          if (row.toLowerCase() === "superdeals2") {
            console.log(data?.superDeals[0]?.childs);
            return data?.superDeals[0] ? (
              <Collection
                smContainer
                gridCount={"mixer-grid-6"}
                link={"/super-deals"}
                linkTitle={`${t("more")} ${t("super-deals")}`}
                keyword="superDeals"
                title={t("super-deals")}
                items={data?.superDeals[0]?.childs}
              />
            ) : (
              ""
            );
          }

          if (row.toLowerCase() === "popularcategories") {
            return data?.popularCategories ? (
              <CircleCollection
                smContainer
                more
                link={"/categories"}
                linkTitle={`${t("more")} ${t("categories")}`}
                keyword="c"
                title={t("popular-categories")}
                items={data?.popularCategories}
              />
            ) : (
              ""
            );
          }

          if (row.toLowerCase() === "worldbrands") {
            return data?.worldBrands?.length > 0 ? (
              <MarqueeComponent
                link={"/brands"}
                linkTitle={`${t("more")} ${t("world-brands")}`}
                title={t("world-brands")}
                items={data?.worldBrands}
              />
            ) : (
              ""
            );
          }

          if (row.toLowerCase() === "localbrands") {
            return data?.localBrands?.length > 0 ? (
              <MarqueeComponent
                link={"/brands"}
                linkTitle={`${t("more")} ${t("local-brands")}`}
                speed={100}
                background={true}
                title={t("local-brands")}
                items={data?.localBrands}
              />
            ) : (
              ""
            );
          }

          // if (row.toLowerCase() === "popularBrands") {
          //   return data?.popularBrands ? (
          //     <SmallCollection
          //       link={"/brands"}
          //       linkTitle={`${t("more")} ${t("brands")}`}
          //       title={t("popular-brands")}
          //       items={data?.popularBrands}
          //     />
          //   ) : (
          //     ""
          //   );
          // }

          if (row.toLowerCase() === "bestsellerperfumes") {
            return data?.bestSellerPerfumes ? (
              <SliderProducts
                index={2}
                link={"/store"}
                linkTitle={`${t("more")} ${t("best-seller-perfumes")}`}
                items={data?.bestSellerPerfumes}
                loading={loading}
                // viewAll={true}
                title={t("best-seller-perfumes")}
              />
            ) : (
              ""
            );
          }

          if (row.toLowerCase() === "bestsellermakeups") {
            return data?.bestSellerMakeups ? (
              <Grid
                key={1}
                link={"/store"}
                // linkTitle={`${t("more")} ${t("best-seller-makeups")}`}
                items={data?.bestSellerMakeups}
                title={t("best-seller-makeups")}
                itemsCount={8}
              />
            ) : (
              ""
            );
          }

          if (row.toLowerCase() === "superdeals") {
            return data?.superDeals[1]?.childs ? (
              <Collection
                gridCount={"mixer-grid-6"}
                link={"/super-deals"}
                linkTitle={`${t("more")} ${t("super-deals")}`}
                keyword="superDeals"
                title={t("super-deals")}
                items={data?.superDeals[1]?.childs}
              />
            ) : (
              ""
            );
          }

          // return (
          //   <Collection
          //     link={"/super-deals"}
          //     linkTitle={`${t("more")} ${t("super-deals")}`}
          //     keyword="category"
          //     title={t("super-deals")}
          //     items={data?.superDeals[1]?.childs}
          //   />
          // );

          if (row.toLowerCase() === "bestsellercosmatics") {
            return data?.bestSellerPerfumes ? (
              <SliderProducts
                index={3}
                link={"/store"}
                linkTitle={`${t("more")} ${t("best-seller-cosmatics")}`}
                items={data?.bestSellerCosmatics}
                loading={loading}
                // viewAll={true}
                title={t("best-seller-cosmatics")}
              />
            ) : (
              ""
            );
          }

          // if (row.toLowerCase() === "bestSellingProducts") {
          //   return data?.bestSellingProducts ? (
          //     <Grid
          //       link={"/store"}
          //       linkTitle={`${t("more")} ${t("best-selling-products")}`}
          //       key={2}
          //       items={data?.bestSellingProducts}
          //       title={t("best-selling-products")}
          //       itemsCount={8}
          //     />
          //   ) : (
          //     ""
          //   );
          // }
        })}

        <Download loading={loading} />
      </div>
    </>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classes from "./UpperHeader.module.css";
import { Config } from "@config/config";
import { useTranslation } from "react-i18next";
import { Avatar } from "@components";
import { useSelector } from "react-redux";
import Android from "@assets/img/android-b.png";
import Ios from "@assets/img/ios-b.png";

export const UpperHeader = ({ socialMedia, whatsAppNumber }) => {
  const { t, i18n } = useTranslation();
  const items = Config?.headerSwitcher;
  const location = useLocation();
  const hideNavbarOnPages = Config?.hideNavbarOnPages;
  const isHidden = hideNavbarOnPages.includes(location.pathname);
  const { data } = useSelector((state) => state.general.appSettings);

  if (isHidden) {
    return null;
  }

  return (
    <>
      <div className={`${classes.headerSwitcher}`}>
        <div className={`container`}>
          <div
            className={`${classes.block} d-flex justify-content-between align-items-center`}>
            <div className={`${classes.left} d-flex`}>
              {/* {items?.map((row, index) => (
            <div key={row.id} className={classes.item}>
              <NavLink activeClassName={classes.test} to={row?.link}>
                {row?.title}
              </NavLink>
            </div>
          ))} */}
              <div className={classes.item}>
                <NavLink to={"/"}>{t("mainTitle")}</NavLink>
              </div>
            </div>

            <div
              className={`${classes.center} d-flex align-items-center justify-content-center`}>
              <a
                className={`${classes.platformItem}`}
                href={data?.androidAppUrl}
                target="_blank"
                rel="noreferrer">
                <Avatar
                  className={`${classes.platformItemImg}`}
                  src={Android}
                  alt="Android"
                  width={1000}
                  height={1000}
                />
                {/* {t("play-store")} */}
              </a>
              <a
                className={`${classes.platformItem}`}
                href={data?.iosAppUrl}
                target="_blank"
                rel="noreferrer">
                <Avatar
                  className={`${classes.platformItemImg}`}
                  src={Ios}
                  alt="Ios"
                  width={1000}
                  height={1000}
                />
                {/* {t("apple-store")} */}
              </a>
            </div>

            <div className={classes.right}>
              <div className={`${classes.socialmedia} d-flex`}>
                {socialMedia &&
                  socialMedia?.map((row, index) => {
                    if (row.title !== "WhatsApp") {
                      return (
                        <a
                          className={`${classes.socialBtn}`}
                          target="_blank"
                          href={row.value}
                          rel="noreferrer">
                          <span
                            className={`icon-${row.title.toLowerCase()} ${
                              classes.icon
                            }`}></span>
                        </a>
                      );
                    }
                  })}

                {whatsAppNumber ? (
                  <a
                    className={`${classes.socialBtn}`}
                    target="_blank"
                    href={`https://wa.me/${whatsAppNumber}?&text=${t(
                      "hello-have-question-about"
                    )}`}
                    rel="noreferrer">
                    <span className={`icon-whatsapp ${classes.icon}`}></span>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

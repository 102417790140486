import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";
import {
  BrandCircleCard,
  CategoryCardDefault,
  Collection,
  ProductCardDefault,
  VideoCard,
  Seo,
  CircleCollection,
} from "@components";

/** Third Party **/
import { Config } from "@config/config";
import classes from "./Discounts.module.css";
import {
  Avatar,
  Paginate,
  Product,
  CircleSpinner,
  CategoryCard,
  SearchBar,
  SlideShow,
  HeadingBar,
  BrandsVideos,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTvPromos,
  fetchSliders,
  fetchCategories,
  fetchBrands,
  fetchProducts,
} from "@store/discounts";
import { useTranslation } from "react-i18next";

export const Discounts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState(null);
  const { data: tvPromos, loading: tvPromosLoading } = useSelector(
    (state) => state.discounts.tvPromos
  );
  const { data: sliders, loading: slidersLoading } = useSelector(
    (state) => state.discounts.sliders
  );

  const { data: categories, loading: categoriesLoading } = useSelector(
    (state) => state.discounts.categories
  );

  const { data: brands, loading: brandsLoading } = useSelector(
    (state) => state.discounts.brands
  );

  const { data: products, loading: productsLoading } = useSelector(
    (state) => state.discounts.products
  );

  useEffect(() => {
    dispatch(fetchTvPromos());
    dispatch(fetchSliders());
    dispatch(fetchCategories());
    dispatch(fetchBrands());
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("discounts")}`} />
      <div className={classes.container}>
        <div className={classes.block}>
          <SlideShow items={sliders} />

          <BrandsVideos />

          <div className={`${classes.categoriesContainer}`}>
            <div className={`container`}>
              <HeadingBar
                title={`${t("categories-discounts")} (${
                  categories?.length ?? 0
                })`}
                linkTitle={`${t("more")} ${t("categories")}`}
                link={"/categories"}
              />
              <CircleCollection
                items={categories}
                keyword={"c"}
                noContainer
                noPadding
              />
              {/* <div className={`${classes.categoriesGrid}`}>
                {categories?.map((row, index) => {
                  return (
                    <CategoryCardDefault
                      row={row}
                      keyword={"c"}
                      keywordID={row?.id}
                    />
                  );
                })}
              </div> */}
            </div>
          </div>
          <div className={`${classes.brandsContainer}`}>
            <div className={`container`}>
              <HeadingBar
                title={`${t("brands-discounts")} (${brands?.length ?? 0})`}
                linkTitle={`${t("more")} ${t("brands")}`}
                link={"/brands"}
              />
              <CircleCollection
                items={brands}
                keyword={"b"}
                noContainer
                noPadding
              />
              {/* <div className={`${classes.brandsGrid}`}>
                {brands?.map((row, index) => {
                  return <CircleCard row={row} />;
                })}
              </div> */}
            </div>
          </div>

          <div className={`${classes.productsContainer}`}>
            <div className={`container`}>
              <HeadingBar
                title={`${t("products-discounts")} (${products?.length ?? 0})`}
              />
              <div className={`${classes.productsGrid}`}>
                {products?.map((row, index) => {
                  return <ProductCardDefault row={row} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import classes from "./Header.module.css";
import ReactPlayer from "react-player";
import { CircleSpinner } from "@components";
import { NavLink } from "react-router-dom";
import { slug } from "@services";

export const Header = ({ items, loading }) => {
  const [keyword, setKeyword] = useState(null);
  console.log(items);
  useEffect(() => {
    if (items) {
      if (items[0]?.module === "brands") {
        setKeyword("b");
      }
    }
  }, [items, setKeyword]);
  return loading ? (
    <CircleSpinner className={"pt-5 pb-5"} size={"lg"} />
  ) : (
    <>
      <div className={`container`}>
        <div className={classes.headerWrapper} data-aos="zoom-out">
          <NavLink
            to={
              items
                ? `/${keyword}/${slug(items[0]?.title)}/${
                    items[0]?.encryptModelId
                  }?&cid=${items[0]?.moduleId}`
                : "/"
            }>
            <div className={`${classes.overlay}`}></div>
            <div className={classes.mainVideo}>
              {/* <ReactPlayer
              className={classes.videoPlayer}
              url={items ? items[0]?.media?.url : null}
              playing={true}
              playsInline
              loop
              autoPlay
              muted
            /> */}
              <ReactPlayer
                className={classes.videoPlayer}
                url={items ? items[0]?.media?.url : null}
                loop={true}
                playing={true}
                playsinline={true}
                volume={0.5}
                muted={true}
                onReady={(e) => {
                  const player = e.getInternalPlayer();
                  player.setAttribute("muted", "true");
                }}
              />
            </div>
            <div className="container">
              <div className={`${classes.videoTitle}`}>
                {items ? items[0]?.title : null}
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import classes from "./Search.module.css";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Avatar, CircleSpinner, ProductCardDefault } from "@components";
import { useTranslation } from "react-i18next";
import EmptySearch from "@assets/img/empty-search.svg";

/** Redux **/
import { fetchSearch } from "@store/search";
import { useDispatch, useSelector } from "react-redux";

export const Search = ({ onDataFromSearch, searchIsActive, className }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading } = useSelector((state) => state.search.search);
  const [searchValue, setSearchValue] = useState();

  const sendDataToParent = () => {
    onDataFromSearch(!searchIsActive);
  };

  const onSearch = (e) => {
    const value = e.target.value;
    if (value.length > 3) {
      dispatch(fetchSearch({ search: value }));
      setSearchValue(value);
    }
  };

  const onRedirectSearch = () => {
    onDataFromSearch(!searchIsActive);
    if (searchValue?.length > 0) {
      navigate(`/search?q=${searchValue}`);
    } else {
      // navigate(`/search`);
      return;
    }
  };

  const onProductRedirect = (id) => {
    navigate(`/products/${+id}`);
    onDataFromSearch(!searchIsActive);
  };

  const onHandleSearch = () => {
    onDataFromSearch(!searchIsActive);
  };

  return (
    <>
      <div className={`${classes.searchBar} ${className}`}>
        <div className="container">
          <div
            className={`${classes.searchContent} d-flex align-items-center justify-content-between`}>
            <div className={`${classes.searchBox} d-flex align-items-center`}>
              <span className={`icon-search icon ${classes.searchIcon}`}></span>
              <input
                onChange={onSearch}
                type="text"
                placeholder={t("search")}
              />
            </div>

            <span
              className={`icon-cancel icon ${classes.exitIcon}`}
              onClick={sendDataToParent}></span>
          </div>

          <div
            className={`${classes.minMsg} ${
              searchValue?.length > 3 ? classes.active : ""
            }`}>
            {t("enter-chars-description")}
          </div>

          <div className={`${classes.searchResuts}`}>
            <div className={``}>
              {loading ? (
                <CircleSpinner className={"pt-3 pb-3"} size={"lg"} />
              ) : (
                <>
                  {data?.length > 0 ? (
                    <>
                      <div className={`${classes.showAll}`}>
                        {searchValue?.length > 0 ? (
                          <div onClick={onRedirectSearch}>{t("showAll")}</div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className={`cards-grid-four`}>
                        {data?.slice(0, 4).map((row, index) => {
                          // <ProductCardDefault row={row} />
                          return (
                            <ProductCardDefault
                              row={row}
                              onHandleSearchFromParent={onHandleSearch}
                            />
                            // <div key={row.id} className={`card-item`}>
                            //   <div onClick={() => onProductRedirect(row.id)}>
                            //     <Avatar
                            //       className={`${classes.image} card-item-image card-item-image-four`}
                            //       src={row?.media?.url}
                            //       alt={row.title}
                            //     />

                            //     <div className={`card-item-title`}>
                            //       {row.title}
                            //     </div>
                            //   </div>
                            //   {row?.oldPrice > row?.price ? (
                            //     <div
                            //       className={`${classes.price} card-item-price-red`}>
                            //       {row?.price} {t("currency")}
                            //       <span
                            //         className={`${classes.oldPrice} card-item-old-price`}>
                            //         {row?.oldPrice} {t("currency")}
                            //       </span>
                            //     </div>
                            //   ) : (
                            //     <div
                            //       className={`card-item-price card-item-price-gray`}>
                            //       {row?.price} {t("currency")}
                            //     </div>
                            //   )}
                            // </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className={`${classes.emptySearchContainer}`}>
                      <Avatar
                        className={classes.emptySearch}
                        src={EmptySearch}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`${classes.backdrop}`}></div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";

/** Third Party **/
import { Config } from "@config/config";
import classes from "./Categories.module.css";
import {
  Avatar,
  Paginate,
  Product,
  CircleSpinner,
  CategoryCard,
  CategoryCardDefault,
  SearchBar,
  SlideShow,
  BrandsVideos,
  Seo,
  CategoryCardBanner,
  CategoryCardDouble,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, handlePagePagination } from "@store/categories";
import { fetchBanners } from "store/general";
import { useTranslation } from "react-i18next";
import { slug } from "@services";

export const Categories = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const idQuery = new URLSearchParams(location.search).get("cid");
  const categoryQuery = new URLSearchParams(location.search).get("c");
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categories, setCategories] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [search, setSearch] = useState(null);
  const { data, paginate, loading, pagePaginate } = useSelector(
    (state) => state.categories.categories
  );
  const { data: banners, loading: bannersLoading } = useSelector(
    (state) => state.general.banners
  );

  const onSearch = (data) => {
    setSearch(data);
    dispatch(handlePagePagination({ page: 1 }));
    if (data.length > 0) {
      dispatch(fetchCategories({ parentId: idQuery, search: data, page: 1 }));
    } else {
      dispatch(fetchCategories({ parentId: idQuery, page: 1 }));
    }
  };

  const onPagePaginate = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(fetchCategories({ parentId: idQuery, page: data, search }));
    } else {
      dispatch(fetchCategories({ parentId: idQuery, search }));
    }
  };

  useEffect(() => {
    dispatch(fetchCategories({ parentId: idQuery }));
    dispatch(fetchBanners());
  }, [dispatch, idQuery]);

  useEffect(() => {
    if (idQuery) {
      setCurrentCategory(idQuery);
    } else {
      setCurrentCategory(null);
    }
  }, [setCurrentCategory, idQuery]);

  useEffect(() => {
    if (data) {
      setCategories(data[0]?.childs);
      setCurrentId(data[0]?.id);
    }
  }, [data]);

  const onToggleCategories = (id, childs = null) => {
    setCurrentId(id);
    setCategories(childs);
  };

  return (
    <>
      <Seo title={`${Config.name} | ${t("categories")}`} />
      <div
        className={classes.container}
        style={{
          fontFamily: i18n?.language === "en" ? "Rounded Elegance" : "",
        }}>
        <div className={`container`}>
          {loading ? (
            <CircleSpinner className={"pt-5"} size="lg" />
          ) : (
            <>
              <div className={`${classes.toolbar} scroller`}>
                <div className={`${classes.tabsContainer}`}>
                  {data?.map((row, index) => {
                    if (row?.childs?.length === 0) {
                      return (
                        <NavLink
                          to={`/c/${slug(row?.title)}/${row?.encryptId}?cid=${
                            row?.id
                          }`}
                          onClick={() => onToggleCategories(row?.id)}
                          className={`${classes.tabBtn} ${
                            +currentId === row?.id ? classes.active : ""
                          }`}>
                          {row?.title}
                        </NavLink>
                      );
                    } else {
                      return (
                        <div
                          onClick={() =>
                            onToggleCategories(row?.id, row?.childs)
                          }
                          className={`${classes.tabBtn} ${
                            +currentId === row?.id ? classes.active : ""
                          }`}>
                          {row?.title}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>

              <div className={`${classes.block}`}>
                <div className={`${classes.grid}`}>
                  {categories?.map((row, index) => {
                    return <CategoryCardDouble row={row} />;
                  })}
                </div>
              </div>
            </>
          )}
        </div>

        <BrandsVideos />
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import classes from "./BrandsVideos.module.css";
import { Config } from "@config";
import { HeadingBar, VideoCard } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { fetchTvPromos } from "@store/discounts";
import { useTranslation } from "react-i18next";

export const BrandsVideos = ({ items }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: tvPromos, loading: tvPromosLoading } = useSelector(
    (state) => state.discounts.tvPromos
  );

  useEffect(() => {
    dispatch(fetchTvPromos());
  }, [dispatch]);
  return (
    <div className={`${classes.promosContainer}`}>
      <div className={`container`}>
        <HeadingBar title={`${t("new-brands")} (${tvPromos?.length ?? 0})`} />
        <div className={`${classes.promosGrid}`}>
          {tvPromos?.map((row, index) => {
            return <VideoCard row={row} />;
          })}
        </div>
      </div>
    </div>
  );
};

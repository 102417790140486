import React, { useState } from "react";
import classes from "./Marquee.module.css";
import { Avatar } from "@components";
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BrandCircleCard, HeadingBar } from "@components";

export const MarqueeComponent = ({ items, title, background, speed }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div
        data-aos="zoom-out"
        className={classes.container}
        style={{
          background: `${background ? "#f9f9f9" : ""}`,
        }}>
        <div className={`${classes.wrapper}`}>
          <div className={`${classes.block}`}>
            <div className="container">
              <HeadingBar
                title={title}
                linkTitle={`${t("more")} ${t("brands")}`}
                link="/brands"
              />
              <Marquee
                className={`${classes.marquee}`}
                direction={i18n.language === "en" ? "left" : "right"}
                pauseOnHover={true}
                speed={speed ? speed : 50}
                gradient={100}
                autoFill={true}
                gradientWidth={100}>
                {items?.map((row, index) => {
                  return <BrandCircleCard row={row} />;
                })}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

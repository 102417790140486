import React, { useState, useEffect } from "react";
import classes from "./ProductCardDefault.module.css";
import {
  Avatar,
  OnSale,
  Bookmark,
  ProductCardActions,
  Share,
} from "@components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwt } from "@services";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchCart, addCart, clearState } from "@store/cart";
import { useDispatch, useSelector } from "react-redux";

export const ProductCardDefault = ({
  row,
  onHandleSearchFromParent = null,
}) => {
  const { t } = useTranslation();
  // const jwToken = jwt();
  // const dispatch = useDispatch();
  // const [currentId, setCurrentId] = useState(null);
  // const {
  //   data: cartData,
  //   loading: cartLoading,
  //   success: successAddToCart,
  // } = useSelector((state) => state.cart.addCart);

  const onHandleSearch = () => {
    if (onHandleSearchFromParent) {
      onHandleSearchFromParent();
    }
  };

  return (
    <article className={`${classes.card}`} data-aos="zoom-out">
      {/* <Bookmark row={row} /> */}
      <OnSale sale={row?.hasOffer} />
      <NavLink to={`/p/${row?.encryptId}`} onClick={onHandleSearch}>
        <Avatar src={row?.media?.url} className={classes.cardImg} />
      </NavLink>
      <div className={`${classes.cardInfo}`}>
        <div className={`${classes.cardInfoTop}`}>
          <h3 className={`${classes.cardTitle} text-capitalize`}>
            {row?.title}
          </h3>
        </div>
        {row?.oldPrice > row?.price ? (
          <div className={`${classes.price} card-item-price-red`}>
            {row?.price} {t("currency")}
            <span className={`${classes.oldPrice} card-item-old-price`}>
              {row?.oldPrice} {t("currency")}
            </span>
          </div>
        ) : (
          <span className={`${classes.cardBy}`}>
            <div className={`${classes.cardAuthor}`}>
              {row?.price} {t("currency")}
            </div>
          </span>
        )}

        <ProductCardActions row={row} />
      </div>
    </article>
  );
};

import React, { useState } from "react";
import classes from "./Products.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Product, EmptyProductsMsg, ProductCardDefault } from "@components";
import { useTranslation } from "react-i18next";

export const Products = ({ items, sortGrid }) => {
  const { t } = useTranslation();
  return (
    <div className={`${classes.container} text-center`}>
      <div className={`container`}>
        <div className={classes.block}>
          {items?.length > 0 ? (
            <div className={`cards-grid-four`}>
              {items?.map((row, index) => (
                <ProductCardDefault
                  key={row.id}
                  row={row}
                  sortGrid={sortGrid}
                />
              ))}
            </div>
          ) : (
            <EmptyProductsMsg text={t("no-data-description")} />
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./BrandCircleCard.module.css";
import { Avatar, OnSale } from "@components";
import { slug } from "@services";

export const BrandCircleCard = ({ row, className, noSpaces }) => {
  return (
    <NavLink
      className={classes.cardLink}
      to={`/b/${slug(row?.title)}/${row?.encryptId}?cid=${row?.id}`}
      style={{ paddingBlock: noSpaces ? "0" : "" }}>
      <OnSale sale={row?.hasOffer} />
      {/* Math.random().toString(16).substr(-6) */}
      <div
        className={`${classes.card}`}
        style={{ marginInline: noSpaces ? "0" : "" }}>
        {/* <div className={`${classes.details}`}>
                        <div className={`${classes.title}`}>{row?.title}</div>
                        <div className={`${classes.action}`}>
                          <button className={`btn btn-corner`}>
                            View
                            <span className={`icon-redirection icon`}></span>
                          </button>
                        </div>
                      </div> */}
        <div className={`${classes.brandView}`}>
          <Avatar
            className={classes.image}
            src={row?.media?.url}
            alt={row?.title}
          />
        </div>
      </div>
    </NavLink>
  );
};

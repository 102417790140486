import React, { useState } from "react";
import classes from "./SlideShow.module.css";
import { Avatar } from "@components";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";
// import "swiper/modules/effect-fade/effect-fade.min.css";
import { SlideCard } from "./slideCard/SlideCard";

export const SlideShow = ({ items, keyword, noBottomSpace }) => {
  return (
    <>
      <div className={`container ${classes.mainContainer}`}>
        <div className={classes.container} data-aos="zoom-out-down">
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectFade,
            ]}
            // effect="fade"
            // fadeEffect={{ crossFade: true }}
            spaceBetween={20}
            slidesPerView={1}
            navigation={{
              prevEl: "",
              nextEl: "",
            }}
            pagination={{ clickable: true }}
            // pagination={{ el: ".swiper-pagination-home", clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            loop={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={1000}
            className={classes.swiperHeader}>
            {keyword === "brands"
              ? items?.map((row, index) => {
                  if (row?.displayIn === "brands") {
                    return (
                      <SwiperSlide key={row.id} className={classes.swiperSlide}>
                        <SlideCard row={row} />
                      </SwiperSlide>
                    );
                  }
                })
              : ""}

            {keyword === "categories"
              ? items?.map((row, index) => {
                  if (
                    row?.displayIn === "mainCategories" ||
                    row?.displayIn === "subCategories" ||
                    row?.displayIn === "sub2Categories"
                  ) {
                    return (
                      <SwiperSlide key={row.id} className={classes.swiperSlide}>
                        <SlideCard row={row} />
                      </SwiperSlide>
                    );
                  }
                })
              : ""}

            {!keyword
              ? items?.map((row, index) => {
                  return (
                    <SwiperSlide key={row.id} className={classes.swiperSlide}>
                      <SlideCard row={row} />
                    </SwiperSlide>
                  );
                })
              : ""}
          </Swiper>
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SlideShow } from "@components";

/** Third Party **/
import { Config } from "@config/config";
import classes from "./Brands.module.css";
import {
  Avatar,
  Paginate,
  Product,
  CircleSpinner,
  BrandCard,
  BrandCircleCard,
  SearchBar,
  BrandsVideos,
  Seo,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands, handlePagePagination } from "@store/brands";
import { fetchBanners } from "store/general";

export const Brands = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const { data, paginate, loading, pagePaginate } = useSelector(
    (state) => state.brands.brands
  );
  const { data: banners, loading: bannersLoading } = useSelector(
    (state) => state.general.banners
  );

  const onSearch = (data) => {
    setSearch(data);
    dispatch(handlePagePagination({ page: 1 }));
    if (data.length > 0) {
      dispatch(fetchBrands({ search: data, page: 1 }));
    } else {
      dispatch(fetchBrands({ page: 1 }));
    }
  };

  const onPagePaginate = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      dispatch(fetchBrands({ page: data, search }));
    } else {
      dispatch(fetchBrands({ search }));
    }
  };

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchBanners());
  }, [dispatch]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("brands")}`} />
      <div className={classes.container}>
        <div className={`${classes.slideShowContainer}`}>
          <SlideShow items={banners} keyword="brands" />
        </div>
        <BrandsVideos />
        <div className={`container text-center`}>
          <div
            className={`${classes.header} title-container text-center d-flex align-items-center justify-content-between pb-4`}>
            <div className={`${classes.mainTitle} title`}>{t("brands")}</div>
            <SearchBar search={search} onSearchFromParent={onSearch} />
          </div>

          <div className={classes.block}>
            <div className={`${classes.data}`}>
              {loading ? (
                <CircleSpinner size="lg" />
              ) : (
                <>
                  <div className={`${classes.grid}`}>
                    {data &&
                      data?.map((row, index) => {
                        return <BrandCircleCard row={row} noSpaces />;
                      })}
                  </div>
                </>
              )}
            </div>
            {paginate?.last_page > 1 ? (
              <Paginate
                page={pagePaginate}
                count={paginate?.last_page}
                onPage={onPagePaginate}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

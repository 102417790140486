import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";

/** Third Party **/
import { Config } from "@config/config";
import classes from "./Search.module.css";
import {
  Avatar,
  Paginate,
  Product,
  CircleSpinner,
  EmptyProductsMsg,
  ProductCardDefault,
  Seo,
} from "@components";
import { useTranslation } from "react-i18next";

/** Redux **/
import { fetchSearch, handlePagePagination } from "@store/search";
import { useDispatch, useSelector } from "react-redux";

export const Search = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, paginate, pagePaginate, loading } = useSelector(
    (state) => state.search.search
  );
  const activeQuery = new URLSearchParams(location.search).get("q");

  const onPagePaginate = (data) => {
    dispatch(handlePagePagination({ page: data }));
    if (data > 1) {
      if (activeQuery) {
        dispatch(
          fetchSearch({
            search: activeQuery,
            page: data,
          })
        );
      } else {
        dispatch(fetchSearch({ page: data }));
      }
    } else {
      dispatch(
        fetchSearch({
          search: activeQuery,
        })
      );
    }
  };

  useEffect(() => {
    if (activeQuery) {
      dispatch(fetchSearch({ search: activeQuery }));
    }
  }, [dispatch, location.search]);

  return (
    <>
      <Seo title={`${Config.name} | ${t("search")}`} />
      <div className={classes.container}>
        <div className={`container text-center`}>
          <div className={`title-container text-center`}>
            <div className={`title`}>{t("search")}</div>
            {paginate?.total > 0 ? (
              <div className={`text pt-3`}>
                {paginate?.total} {t("results-for")} "{activeQuery}"
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={classes.block}>
            {loading ? (
              <CircleSpinner className={"pb-5"} size="lg" />
            ) : data?.length > 0 ? (
              <>
                <div className={`${classes.grid} cards-grid-four`}>
                  {data?.map((row, index) => (
                    <ProductCardDefault key={row.id} row={row} />
                  ))}
                </div>
                {paginate?.last_page > 1 ? (
                  <Paginate
                    page={pagePaginate}
                    count={paginate?.last_page}
                    onPage={onPagePaginate}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <EmptyProductsMsg
                text={t("no-data-description")}
                fontSize={25}
                className={"pt-3"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
